.swiper-button-prev:after{
    margin-right: 35px;
    font-size: 25px;
    font-weight: bolder;
    color: #e87722;
}

.swiper-button-next:after{
    font-size: 25px;
    margin-left: 35px;
    font-weight: bolder;
    color: #e87722;
}

@media only screen and (max-width: 767px) {
    .swiper-button-prev:after{
        margin-right: 17px;
    }
    
    .swiper-button-next:after{
        margin-left: 17px;

    }
    
}

@media only screen and (max-width: 490px) {
    .swiper-button-prev:after{
        margin-right: 17px;
        font-size: 20px;
    }
    
    .swiper-button-next:after{
        margin-left: 17px;
        font-size: 20px;
    }
    
}

@media only screen and (max-width: 425px) {
    .swiper-button-prev:after{
        margin-right: 15px;
        font-size: 20px;
    }
    
    .swiper-button-next:after{
        margin-left: 15px;
        font-size: 20px;
    }
    
}

@media only screen and (max-width: 375px) {
    .swiper-button-prev:after{
        margin-right: 20px;
    }
    
    .swiper-button-next:after{
        margin-left: 20px;
    }
    
}