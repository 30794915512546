.containerTabs {
  margin-top: 50px;
}

.containerLoading{
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerDetails {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerUl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.containerUl ul {
  text-align: left;
}

.navTabs {
  --bs-nav-tabs-border-color: #0e64a3 !important;
}

.buttonTab {
  --bs-nav-tabs-link-active-border-color: #0e64a3 #0e64a3 #fff;
  color: black !important;
}

.buttonTab:hover {
  color: #0e64a3 !important;
}

.containerVideosTab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerVideos {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerVideoRes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.videoIframe {
  width: 320px;
  height: 240px;
}

@media only screen and (min-width: 2561px) {

  .videoIframe {
    width: 410px;
    height: 325px;
  }
}

@media only screen and (max-width: 2560px) {

  .videoIframe {
    width: 410px;
    height: 325px;
  }
}


@media only screen and (max-width: 1399px) {
  .videoIframe {
    width: 350px;
    height: 255px;
  }
}


@media only screen and (max-width: 1199px) {
  .videoIframe {
    width: 292px;
    height: 210px;
  }

}


@media only screen and (max-width: 991px) {
  .videoIframe {
    width: 330px;
    height: 230px;
  }
}

@media only screen and (max-width: 768px) {
  .containerUl {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .containerVideos {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .containerVideoRes {
    justify-content: center;
  }

  .videoIframe {
    width: 500px;
    height: 300px;
  }

 

  .containerLi li {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 540px) {

  .videoIframe {
    width: 480px;
    height: 280px;
  }

}

@media only screen and (max-width: 425px) {
  .containerUl {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .containerVideos {
    margin-top: 30px;
    margin-bottom: 30px;
  }


  .buttonTab {
    font-size: 0.8rem !important;
  }

  .containerLi li {
    font-size: 0.7rem;
  }

  .videoIframe {
    width: 370px;
    height: 265px;
  }
}

@media only screen and (max-width: 375px) {
  .buttonTab {
    font-size: 0.7rem !important;
  }

  .containerLi li {
    font-size: 0.6rem;
  }

  .videoIframe {
    width: 320px;
    height: 240px;
  }
}

@media only screen and (max-width: 320px) {
  .videoIframe {
    width: 275px;
    height: 195px;
  }
}
