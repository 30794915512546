/* BUSCADOR */
.containerBuscador {
  /* margin-top: 285px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* NUEVO */
  justify-content: center;
  align-items: center;
}
/* BUSCADOR */
.form button {
  border: none;
  background: none;
  color: #0d64a3;
  cursor: pointer;
}
.form {
  position: relative;
  width: 500px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-inline: 0.8em;
  border-radius: 30px;
  -webkit-transition: border-radius 0.5s ease;
  transition: border-radius 0.5s ease;
  background: #fff;
  right: 41px !important;
}

/* DROPDOWN */
.contresult {
  position: absolute;
  z-index: 3001;
  width: 500px;
  max-height: 360px;
  right: 537px !important;
  top: 75px !important;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: white;
}
.result {
  overflow-y: scroll;
  width: 550px;
  max-height: 360px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
}
.imgSearch {
  height: 50px;
}
.spanItem {
  text-decoration: none;
  color: black;
}
.spanSearch {
  margin-left: 10px;
}
.itemsdrop {
  height: 60px;
  border-top: 1px solid #0d64a3;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.itemsdrop:hover {
  background-color: whitesmoke;
}
/* ESTILO Input */
.input {
  font-size: 1rem;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-inline: 0.5em;
  padding-block: 0.7em;
  border: none;
}
/* Estilo de animación border */
.form:before {
  content: "";
  position: absolute;
  background: #0d64a3;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
/* Hover on Input */
.form:focus-within {
  border-radius: 1px;
}

input:focus {
  outline: none;
}
/* here is code of animated border */
.form:focus-within:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
/* styling of close button */
/* == you can click the close button to remove text == */
.reset {
  border: none;
  background: none;
  opacity: 0;
  visibility: hidden;
}
/* close button shown when typing */
input:not(:-moz-placeholder-shown) ~ .reset {
  opacity: 1;
  visibility: visible;
}
input:not(:-ms-input-placeholder) ~ .reset {
  opacity: 1;
  visibility: visible;
}
input:not(:placeholder-shown) ~ .reset {
  opacity: 1;
  visibility: visible;
}
/* sizing svg icons */
.form svg {
  width: 17px;
  margin-top: 3px;
}

/* SCROLL */
.result::-webkit-scrollbar {
  width: 5px;
}

.result::-webkit-scrollbar-track {
  background: transparent;
}

.result::-webkit-scrollbar-thumb {
  background-color: #e96d19;
  border-radius: 20px;
}

/* RESPONSIVE */
@media only screen and (max-width: 2560px) {
    .contresult {
    position: absolute;
    z-index: 3001;
    width: 500px;
    max-height: 360px;
    left: 670px;
    top: 75px;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
}
/* 1920 */
@media only screen and (max-width: 1920px) {
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 500px;
    max-height: 360px;
    left: 429px;
    top: 75px;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
}
/* LAPTOP 1440PX */
@media only screen and (max-width: 1440px) {
  /* DROPDOWN */
  .form {
    width: 500px;
    height: 45px;
    margin-right: -197px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 500px;
    max-height: 360px;
    left: 362px;
    top: 75px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
}

@media only screen and (max-width: 1366px) {
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 500px;
    max-height: 360px;
    left: 320px;
    top: 75px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    overflow-y: scroll;
    width: 500px;
    max-height: 360px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
  }
}

@media only screen and (max-width: 1280px) {
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 500px;
    max-height: 360px;
    left: 288px;
    top: 75px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    overflow-y: scroll;
    width: 500px;
    max-height: 360px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
  }
}

/* LAPTOP 1024px */

@media only screen and (max-width: 1024px) {
  .containerBuscador {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .form {
    position: relative;
    width: 350px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
  }
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 350px;
    max-height: 360px;
    left: 246px;
    top: 72px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 350px;
  }
  /* ESTILO Input */
  .input {
    font-size: 12px;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
  }

  .spanSearch {
    font-size: 12px;
  }
}

@media only screen and (max-width: 912px) {
  .form {
    position: relative;
    width: 330px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -190px;
  }
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 330px;
    max-height: 360px;
    left: 212px;
    top: 71px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 330px;
  }
}

@media only screen and (max-width: 820px) {
  .form {
    position: relative;
    width: 330px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -190px;
  }
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 330px;
    max-height: 360px;
    left: 176px;
    top: 71px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 330px;
  }
}

/* TABLET 768px */
@media only screen and (max-width: 768px) {
  .containerBuscador {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .form {
    position: relative;
    width: 350px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -100px;
  }
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 350px;
    max-height: 360px;
    left: 249px;
    top: 58px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 350px;
  }
  /* ESTILO Input */
  .input {
    font-size: 16px;
    /* font-size: 0.78rem; */
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .containerSearch {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .containerBuscador {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .form {
    position: relative;
    width: 650px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 650px;
    max-height: 360px;
    left: 58px;
    top: 48px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 650px;
  }
}

@media only screen and (max-width: 670px) {
  .form {
    position: relative;
    width: 550px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 550px;
    max-height: 360px;
    left: 59px;
    top: 48px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 550px;
  }
}

@media only screen and (max-width: 580px) {
  .form {
    position: relative;
    width: 450px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 450px;
    max-height: 360px;
    left: 64px;
    top: 48px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 450px;
  }
}

@media only screen and (max-width: 540px) {
  .form {
    position: relative;
    width: 450px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 450px;
    max-height: 360px;
    left: 44px;
    top: 48px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 450px;
  }
  .input {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .form {
    position: relative;
    width: 400px;
    height: 37px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 400px;
    max-height: 360px;
    left: 39px;
    top: 48px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 400px;
  }
}

@media only screen and (max-width: 430px) {
  .form {
    position: relative;
    width: 375px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 375px;
    max-height: 360px;
    left: 27px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 375px;
  }
}

@media only screen and (max-width: 428px) {
  .form {
    position: relative;
    width: 375px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 375px;
    max-height: 360px;
    left: 26px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 375px;
  }
}

@media only screen and (max-width: 426px) {
  .form {
    position: relative;
    width: 375px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 375px;
    max-height: 360px;
    left: 25px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 375px;
  }
}

/* MOBILE L 425px */

@media only screen and (max-width: 425px) {
  .containerBuscador {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form button {
    border: none;
    background: none;
    color: #0d64a3;
    cursor: pointer;
  }
  .form {
    position: relative;
    width: 375px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 375px;
    max-height: 360px;
    left: 24px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 375px;
  }
  .itemsdrop:hover {
    background-color: white;
  }
  .spanItem {
    text-decoration: none;
    color: black;
  }
  .imgSearch {
    height: 40px;
  }
  .itemsdrop {
    height: 60px;
    border-top: 1px solid #0d64a3;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  /* ESTILO Input */
  .input {
    /* font-size: 0.92rem; */
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
  }
  /* Estilo de animación border */
  .form:before {
    content: "";
    position: absolute;
    background: #0d64a3;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  /* Hover on Input */
  .form:focus-within {
    border-radius: 1px;
  }

  input:focus {
    outline: none;
  }
  /* here is code of animated border */
  .form:focus-within:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  /* styling of close button */
  /* == you can click the close button to remove text == */
  .reset {
    border: none;
    background: none;
    opacity: 0;
    visibility: hidden;
  }
  /* close button shown when typing */
  input:not(:-moz-placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:-ms-input-placeholder) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  /* sizing svg icons */
  .form svg {
    width: 17px;
    margin-top: 3px;
  }

  .containerButtons {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* LOGIN-CARRITO */
  .loginCartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    width: 100%;
  }

  .userBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cartBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logCart {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoUser {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .form {
    width: 350px;
    margin-right: -70px;
  }
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 350px;
    max-height: 360px;
    left: 31px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 350px;
  }
}

@media only screen and (max-width: 412px) {
  .contresult {
    left: 30px;
  }
}

@media only screen and (max-width: 393px) {
  .form {
    width: 350px;
    margin-right: -70px;
  }
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 350px;
    max-height: 360px;
    left: 21px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 350px;
  }
}

@media only screen and (max-width: 390px) {
  .form {
    width: 350px;
    margin-right: -70px;
  }
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 350px;
    max-height: 360px;
    left: 19px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 350px;
  }
}

/* MOBILE M 375px */

@media only screen and (max-width: 375px) {
  /* BUSCADOR */
  .containerBuscador {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 350px;
    max-height: 360px;
    left: 12px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 350px;
  }
  /* BUSCADOR */
  .form button {
    border: none;
    background: none;
    color: #0d64a3;
    cursor: pointer;
  }
  .form {
    position: relative;
    width: 350px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
    margin-right: -70px;
  }
  /* ESTILO Input */
  .input {
    /* font-size: 0.78rem; */
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
  }
  /* Estilo de animación border */
  .form:before {
    content: "";
    position: absolute;
    background: #0d64a3;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  /* Hover on Input */
  .form:focus-within {
    border-radius: 1px;
  }

  input:focus {
    outline: none;
  }
  /* here is code of animated border */
  .form:focus-within:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  /* styling of close button */
  /* == you can click the close button to remove text == */
  .reset {
    border: none;
    background: none;
    opacity: 0;
    visibility: hidden;
  }
  /* close button shown when typing */
  input:not(:-moz-placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:-ms-input-placeholder) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  /* sizing svg icons */
  .form svg {
    width: 17px;
    margin-top: 3px;
  }
}

@media only screen and (max-width: 365px) {
  .form {
    width: 310px;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 310px;
    max-height: 360px;
    left: 27px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 310px;
  }
}

@media only screen and (max-width: 360px) {
  .form {
    width: 310px;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 310px;
    max-height: 360px;
    left: 24px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 310px;
  }
}

@media only screen and (max-width: 320px) {
  .form {
    width: 300px;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 300px;
    max-height: 360px;
    left: 9px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 300px;
  }
}

@media only screen and (max-width: 310px) {
  .form {
    width: 260px;
    margin-right: -70px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 260px;
    max-height: 360px;
    left: 24px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 260px;
  }
}

@media only screen and (max-width: 280px) {
  .form {
    width: 260px;
    margin-right: -72px;
  }

  .contresult {
    position: absolute;
    z-index: 3001;
    width: 260px;
    max-height: 360px;
    left: 10px;
    top: 51px !important;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
  .result {
    width: 260px;
  }
}
