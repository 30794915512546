.containerLogo {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  background-color: #0068b3;
  width: 160px;
  height: 80px;
  margin-left: 5px;
}

.img {
  width: 100%;
}

.close {
  margin-right: 20px;
  font-size: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  width: 20px;
}

.containerItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 17px;
}

.mobileIcon {
  display: none;
}

.container {
  display: none;
}

.items {
  color: #0068b3;
  font-size: 1.1em;
  font-weight: 500;
}

.containerLogoClose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  height: 90px;
  background-color: #0068b3;
  border-top-right-radius: 20px;
}

.ghost {
  height: 15%;
}

.faqs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 120px;
  bottom:0;
  left: 0px;
  position:absolute;
  gap: 10px;
}

.containerLogout{
  width: 90%;
  height: 50px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.iconLogout{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 100%;
  background-color:white;
  color: #0068b3;
  margin-right: 10px;
  border: solid 2px #0068b3;
}

.containerFaqs {
  width: 90%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.containerFaqs .imgBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 100%;
  background-color:white;
  color: white;
  margin-right: 10px;
  border: solid 2px #0068b3;
}

.containerItemsIcons {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
}

.containerItemsIcons:active{
background-color: #f4f4f4;
border-radius: 20px;

}

.containerItemsIcons i {
  color: #0068b3;
}

.containerItemsIcons2 {
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  margin-left: 6px;
  margin-right: 30px;
  margin-bottom: 15px;
}

.containerItemsIcons2:active{
  background-color: #f4f4f4;
  border-radius: 20px;
  }

.dropDownBack {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #0068b3;
  font-size: 1.1em;
 margin-left: -35px;
}

.dropDownBack span {
  font-size: 1.1em;
  color: #0068b3;
}


.containerSubCategories {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: scroll;
}

.subCategory {
  width: 100%;
  height: auto;
  padding-left: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  top: 0;

}

.subCategory span {
  color: #0068b3;
}
.spanSub{
  font-size: 1.1em;
}

.iconDrop {
  width: 20%;
}

.categories {
  text-decoration: none;
  margin-bottom: 5px;
  width: 100%;
}

.categorySE {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.spanText {
  color: #e87722;
  font-size: 0.9em;
  font-weight: 400;
}

.containerUserData{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.idStrong{
  font-weight: bold;
}

.ayudaBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.imgBtn {
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 768px) {
  .container {
    display: block;
  }
  .mobileIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  svg {
    fill: #fff;
    height: 22px;
  }

  .menu {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 75%;
    height: 100vh;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    transition: 0.5s all ease;
    z-index: 9999;
    border-top-right-radius: 20px;
  }

  .menuClose {
    background-color: #fff;
    position: fixed;
    left: -100%;
    top: 0;
    width: 75%;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: 0.5s all ease;
    z-index: 9999;
    border-top-right-radius: 20px;
  }

  .back{
    position: fixed;
    right: 0;
    top: 0;
    width: 600px;
    height: 100vh;
    flex-direction: column;
    z-index: 99;
    background-color:rgba(82, 82, 82, 0.5);
  }

  .backClose{
    position: absolute;
  }
}


@media only screen and (max-height: 890px) {
  .faqs{
    bottom: 50px;
  }
}

@media only screen and (max-height: 700px) {
  .faqs{
    bottom: 10px;
  }
}

@media only screen and (max-height: 650px) {
  .faqs{
    bottom: -10px;
  }
}