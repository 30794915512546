.swiper {
  padding: 0px 20px 0px 20px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.swiperSlide {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.swiperSlide img {
  width: 100%;
  border-radius: 20px;
}

@media only screen and (max-width: 500px) {
  .swiper {
    padding: 0px 0px 0px 0px;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    margin-bottom: 5px;
    margin-top: -30px;
  }
}
