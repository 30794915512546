.containerBestComponent {
  width: 100%;
  background-color: #fcf0e7;
}

.containerTitle {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-weight: 600;
  color: #3c3c3c;
}
.containerDivCards {
  width: 100%;
  display: flex;
  justify-content: center;
}

.containerCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;

}

.containerPaginate {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerBtn {
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btn {
  border-radius: 100%;
  background-color: #0e64a3;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
}

.currentPage {
  cursor: pointer;
  border-radius: 100%;
  background-color: #e87722;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
}

.prevNextPage {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.pointsBtn {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.containerNoProducts {
  display: flex;
  width: 100%;
}

.containerLoading {
  width: 100%;
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .containerCards{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 1.4rem;
  }

  .btn {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .prevNextPage {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .pointsBtn {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .currentPage {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }
  
  .containerCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}

@media only screen and (max-width: 538px) {
  .title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 438px) {
  .title {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 421px) {

  .containerCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

@media only screen and (max-width: 350px) {
  .title {
    font-size: 1.3rem;
  }
}
