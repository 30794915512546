.allCheckout{
  padding-bottom: 50px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.containerModo {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: left;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 20px;
}

.containerResumen {
  width: 50%;
  gap: 20px;
  justify-content: left;
  padding-top: 10px;
  padding-right: 40px;
  padding-left: 20px;
}
.lock {
  color: #cccccc;
  font-size: 23px;
}
.title1 {
  font-size: 21px;
  font-weight: 900;
  color: #3c3c3c;
}
.title {
  font-size: 21px;
  font-weight: 900;
  color: #3c3c3c;
}

.pago {
  width: 500px;
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  background-color: #2a65a3;
  height: 165px;
}

.enviarA {
  width: 500px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: left;
  text-align: center;
  border-radius: 20px;
  background-color: #2a65a3;
  height: 165px;
  display: grid;
  align-content: center;
  justify-content: center;
}

.subtitle4 {
  font-size: 18px;
  font-weight: 900;
  color: white;
}

.subtitle {
  font-size: 18px;
  font-weight: 900;
  color: white;
  margin-top: 25px;
}

.contPagoEnvio {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.espacioVacio {
  width: 30px;
}
.cont100 {
  width: 100%;
  justify-content: left;
  text-align: left;
}
.cont100med {
  width: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.cont100pad {
  width: 100%;
  margin-top: 8px;
  justify-content: left;
  text-align: left;
  margin-bottom: 18px;
}

.input {
  width: 100%;
  justify-content: left;
  text-align: left;
  border-radius: 20px;
  border: solid 1px #cccccc;
  padding: 3px;
  padding-left: 10px;
}
.input:focus {
  transition: 1s;
  border: solid 1px #2a65a3;
}

.subBlue {
  font-size: 13px;
  font-weight: 900;
  color: #2a65a3;
}
.hr {
  width: 100%;
  border-top: 1px solid #cccccc;
}
.contSpace {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bolder {
  font-weight: 900;
}
.bolderGreen {
  font-weight: 900;
  color: #5fa852;
}

.green {
  color: #5fa852;
}

.bigBolder {
  font-size: 21px;
  font-weight: 900;
  color: #3c3c3c;
}

.miniTitle {
  font-size: 13px;
  width: 100%;
}

.button {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: left;
  text-align: center;
  border-radius: 20px;
  background-color: #2a65a3;
  border: solid #2a65a3;
  font-size: 15px;
  font-weight: 900;
  color: white;
}

.button:disabled {
  background-color: #cccccc;
  border: solid #cccccc;
}

.button:hover {
  transition: 1s;
  background-color: white;
  border: solid #2a65a3;
  color: #2a65a3;
}

.button:disabled:hover {
  background-color: #cccccc;
  border: solid #cccccc;
  color: white;
}

.envio {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

/* Breadcrumb */

.rutas {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  font-size: 12px;
  padding-top: 20px;
  gap: 2px;
}

.inicio {
  text-decoration: none;
  color: black;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inicio:hover {
  color: black;
}
.nameRuta {
  font-weight: 600;
}

.logoCasa{
  font-size: 27px;
  color: black !important;
  fill: black !important;
  padding-bottom: 5px;
}

/* Fin breadcrumb */

.left {
  padding-left: 40px;
  font-weight: 900;
}

.contTotal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: left;
  height: 170px;
  margin-top: 60px;
}
.contCard {
  width: 100%;
  height: 100px;
  padding: 14px;
  display: flex;
  background-color: #f4f4f4;
  border-radius: 20px;
  margin-top: 10px;
}
@supports (object-fit: cover) {
  .containerImg img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center center;
    border-radius: 20px;
  }
}
.img {
  width: 100%;
}
.cardInfo {
  width: 70%;
  padding-left: 15px;
  text-align: left;
}
.cardPrice {
  width: 20%;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
}
.cardTitle {
  font-weight: 900;
  color: #2a65a3;
}
.cardPrecio {
  font-size: 14px;
}
.cardCant {
  color: #929292;
  font-weight: 800;
}

@media only screen and (max-width: 1024px) {
  .rutas {
    padding-left: 40px;
  }
  .containerModo {
    padding-left: 40px;
  }
  .containerResumen {
    padding-right: 40px;
  }
  .envio {
    padding: 9px;
  }
}

@media only screen and (max-width: 827px) {
  .rutas {
    padding-left: 30px;
  }
  .containerModo {
    padding-left: 30px;
  }
  .containerResumen {
    padding-right: 30px;
  }
  .subtitle4 {
    font-size: 16px;
  }
  .subtitle {
    font-size: 16px;
  }
  .envio {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .rutas {
    padding-left: 30px;
  }
  .containerModo {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .containerResumen {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .pago {
    width: 100%;
    height: 140px;
  }

  .enviarA {
    width: 100%;
    height: 140px;
  }
}
@media only screen and (max-width: 500px) {
  .cardTitle {
    width: 228px;
    height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}  
@media only screen and (max-width: 475px) {
  .cardTitle {
    width: 208px;
  }
} 
@media only screen and (max-width: 450px) {
  .cardTitle {
    width: 188px;
  }
} 
@media only screen and (max-width: 425px) {
  .cardTitle {
    width: 170px;
  }
} 
@media only screen and (max-width: 400px) {
  .cardTitle {
    width: 145px;
  }
} 

@media only screen and (max-width: 375px) {
  .rutas {
    padding-left: 20px;
  }
  .containerModo {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cardTitle {
    width: 144px;
  }
}
@media only screen and (max-width: 350px) {
  .cardTitle {
    width: 120px;
  }
} 
