.conteinerCarrouselUp{
    width: 100%;
    background-color: white;
    padding-top: 40px;
    padding-bottom: 40px;
}
.conteinerCarrouselUp h3{
    margin-bottom: 25px;
}

.swiper{
    height: 490px;
}

.displayNoneCarr{
    display: none;
}

.swiperSlide{
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}


