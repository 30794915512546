.card{
  border: 1px solid #d8d8d8;
  width: 950px;
  height: 225px;
  border-radius: 20px;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
}

.containerInfo{
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.containerStock span{
  border-radius: 50px;
  padding: 7px;
  margin: 0;
}

.stockBajo{
  color: #E41D1D;
  background-color: #fde9ea;
}

.stock{
  color: #18c468;
  background-color: #e9fdea;
}

.containerImg{
  width: 85%;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.img{
  width: 80%;
}

.containerSec{
  width: 90%;
  flex-direction: column;
}

.containerData{
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
  border-right: 1px solid #d8d8d8;
}

.imagenInfo{
  display: flex;
  width: 60%;
}

.containerDataName{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cantPorCaja {
  color: rgba(0, 0, 0, 0.603);
  text-align: left;
}

.gramos{
  font-size: 0.9em;
  color: #0d64a3b6;
}

.containerButtonPrice{
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.price{
  color: black;
  font-size: 16px;
  font-weight: 350;
  padding-top: 5px;
  margin-right: 10px;
}

.containerPriceXBox{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.precioxBox{
  font-size: 20px;
  font-weight: 600;
  color: #e87722;
}

.productName{
  margin: 0;
  font-size: 1.3em;
  font-weight: 600;
  color: #0d64a3;
  text-align: left;
}

.productPrice{
  margin: 0;
  font-size: 1.4em;
  font-weight: 700;
  padding-top: 8px;
  color: #e87722;
}

.productPrice2{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-evenly;
}


.containerButtons{
  width: 100%;
  height: 35%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: space-evenly;
}

.containerTrash{
  font-size: 20px;
  color: #e87722;
  cursor: pointer;
}

.imgTrash{
  width: 40px;
  height: 40px;
}

.containerAdd{
  width: 120px;
  height: 45px;
  border: 2px solid #e27725;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.minus {
  width: 20px;
  cursor: pointer;
}

.plus {
  width: 20px;
  cursor: pointer;
}

.quantity{
  font-size: 20px;
  color: #e87722;
  font-weight: 700;
}

@media only screen and (max-width: 1300px) {

  .card{
    width: 950px;
    height: auto;
    display:inline;
    padding-bottom: 10px;
    padding-top: 10px;
    align-items: center;
  }
  .imagenInfo{
    display: flex;
    width: 100%;
  }
  
  .containerButtonPrice{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .productPrice2{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .containerButtons{
  width: 200px;
  height: 35%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: space-evenly;
}

.cantTrash{
width: 100%;
}
}

@media only screen and (max-width: 1024px) {
  .card{
    width: 450px;
    height: auto;
    display:inline;
    padding-bottom: 10px;
    padding-top: 10px;
    align-items: center;
  }

  .containerImg{
    width: 70%;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

  }
  
  .productName{
    /* margin: 0; */
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: 600;
    color: #0d64a3;
    text-decoration: none;
  }
  .containerSec{
    width: 500px;
  }
  .productName{
    text-align: left;
  }
  .containerImg{
    width: 130px;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
/* border: solid 1px purple; */
  }
}

@media only screen and (max-width: 500px) {
  .card{
    width: 100%;
    height: 228px;
  }
  .imagenInfo{
    display: flex;
  }
  .containerInfo{
    width: 125px;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
  .containerImg{
    width: 130px;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
  }
  .containerSec{
    width: 70%;
    display: flex-start;
    flex-direction: column;
    height: 60%;
    margin-top: -1px;
  }
  .productName{
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #0d64a3;
    text-decoration: none;
    text-align: left;
  }
  .cantPorCaja{
    font-size:0.7rem;
  }
  .containerData{
    width: 100%;
    height: 80%;
    display:flexbox;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    display: flex-start;
    padding-left: 5px;
    border-right: 0px solid #d8d8d8;
    /* border: solid 1px red; */
  }
  .containerDataName{
    height: 90px;
  }
  
  .containerPriceXBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
   margin-top: -10px;
   width: 100%;
  }
  .containerButtonPrice{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: -50px;
  }
  .textPrice1{
    font-size: 0.8rem;
  }
  .textPrice{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-right: 10px;
    margin-top: -14px;
  }
  .textPrice0{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-right: 10px;
  }
  .precioxBox{
    font-size: 0.9rem;
    font-weight: 600;
    color: #e87722;
  }
  .price{
    font-size: 0.9rem;
  }
  .productPrice{
    margin: 0;
    font-size: 1.2em;
    font-weight: 700;
    padding-top: 8px;
    color: #e87722;
  }
  .containerAdd {
    padding-top: 1px;
    padding-bottom: 1px;
    width: 80px;
    height: 29px;
  }

  .quantity {
    font-size: 15px;
    margin-top: 4px;
  }
  .minus , .plus{
    width: 14px;
    display: flex;
  }
  .imgTrash{
    width: 28px;
    height: 28px;
  }
  .containerTrash{
    padding-left: 10px;
    padding-bottom: 3px;
  }
  .containerButtons{
    width: 100%;
    height: 35%;
    display: flex;
    align-items: right;
    padding-left: 10px;
    justify-content: right;
    padding-right: 10px;
  }
  .productPrice2{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
