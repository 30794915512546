.allPrint{
  padding-bottom: 50px;
}

.container {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.container2 {
  margin: 0px auto;
  align-items: center;
  text-align: center;
  width: 810px;
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 50px;
  margin-left: -15px;
}

.title {
  color: #2a65a3;
  font-size: 28px;
  font-weight: 500;
  word-spacing: 0.5em;
}

.containerResumen {
  width: 100%;
  gap: 20px;
  justify-content: left;
  padding-top: 10px;
}

.cont100pad {
  width: 100%;
  margin-top: 8px;
  justify-content: left;
  text-align: left;
  margin-bottom: 18px;
}
.cont100 {
  width: 100%;
  justify-content: left;
  text-align: left;
  word-spacing: 1em;
}

.title {
  font-size: 21px;
  font-weight: 900;
  color: #3c3c3c;
}
.contCard {
  width: 100%;
  height: 80px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 5px;
  display: flex;
  border-radius: 20px;
  word-spacing: 0.5em;
}
@supports (object-fit: cover) {
  .containerImg img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    object-position: center center;
    border-radius: 20px;
  }
}
.img {
  width: 100%;
}
.cardInfo {
  width: 70%;
  padding-left: 15px;
  text-align: left;
  display: grid;
  align-content: center;
  justify-content: left;
}
.cardPrice {
  width: 30%;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
}
.cardTitle {
  font-weight: 900;
}
.cardPrecio {
  font-size: 14px;
}
.cardCant {
  color: #929292;
  font-weight: 800;
}

.contTicket {
  margin-top: 10px;
  border: solid 2px #c1c0c0;
  border-radius: 20px;
}


.contHola{
    width: 100%;
    height: 50px;
    display:flex;
    word-spacing: 0.5em;
}
.encTitle {
  width: 88%;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;

}
.encInfo {
  width: 30%;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;

}

.hr {
  width: 100%;
  border-top: 1px solid #cccccc;
  margin-top: 13px
}

.contSpace {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 800;
  color: #3c3c3c;
  word-spacing: 0.5em;
}
.contSpacetitle{
  word-spacing: 0.5em;
}

