.allCart{
  padding-bottom: 50px;
}

.containerdespachoCart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.toaster{
  margin-top: 130px;
  z-index: 50;
}

/* Breadcrumb */
.rutas {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-top: 20px;
}

.breadcumb {
  display: flex;
  align-items: center;
}

.inicio {
  text-decoration: none;
  color: black;
  font-size: 12px;
}
.inicio :hover {
  color: black;
}

.nameRuta {
  padding-top: 6px;
  font-weight: 600;
}


.logoCasa{
  font-size: 27px;
  color: black !important;
  fill: black !important;
  padding-bottom: 5px;
}


/* Fin Breadcrumb */

.containerDatos {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.despaEmpresa {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 70%;
}

.despacho {
  height: 100%;
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;
}

.titleDespacho {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #0d65a3;
  padding-bottom: 10px;
}

.subTitle {
  border-top: 3px solid #0d65a3;
  width: 100%;
  height: 100%;
  text-align: left;
  font-weight: 450;
  display: flex;
  justify-content: flex-start;

}
.subt {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  margin-top: 25px;
}

.fecha {
  color: #929292;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 7px;
  text-transform: none;
  margin-bottom: 5px;
}

.selectFecha {
  width: 100%;
  appearance: none;
  border: 1px solid #e87722;
  border-radius: 30px;
  background: white;
  padding: 8px 17px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #e87722;
  margin-top: 3px;
}

.selectFecha:hover {
  cursor: pointer;
}

.selectFecha:focus {
  outline: none;
}

.horario {
  text-align: start;
  font-size: 14px;
  line-height: 1.42857143;
  color: #3c3c3c;
}

.empresa {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleEmpresa {
  color: #0f7bc9;
  font-weight: 600;
  font-size: 17px;
}

.lista {
  width: 100%;
  height: 100%;
  margin-top: 15px;
}

.spanEspacio {
  display: flex;
  justify-content: space-between;
}

.cambiarLocal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.a {
  color: #e87722;
  font-weight: 900;
}

.spanBotonesa {
  font-weight: 900;
}

.containerDespacho {
  width: 100%;
  height: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.DespachoBotones {
  margin: 0px auto;
  width: 1600px;
  padding-right: 30px;
  padding-left: 30px;
}

.containerBotones {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 2%;
  margin-bottom: 2%;
}

.botonesa {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.spanLength {
  font-size: 1.1em;
  font-weight: 600;
  color: #0f7bc9;
}

.listAndForm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerAll {
  display: flex;
  width: 1600px;
  gap: 50px;
  padding-right: 30px;
  padding-left: 30px;
}

.containerListProducts {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.containerCardData {
  width: 40%;
  display: flex;
  justify-content: right;
  margin-top: -1.5%;
}

.cardData {
  border: 2px solid #fef1e9;
  width: 100%;
  height: 600px;
  border-radius: 20px;
  background-color: #fef1e9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.containText {
  border-bottom: 1px solid #cdcdcd;
  width: 90%;
  margin-bottom: 10px;
}

.cardDataText {
  padding-top: 40px;
  padding-bottom: 8px;
  color: #0d64a3;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
}

.containerButtons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.button {
  border: none;
  background-color: inherit;
  color: #e87722;
  font-size: 1.2em;
  font-weight: 900;
}

.button span {
  color: #e96d19 !important;
  margin: 0px !important;
  padding: 10px !important;
  padding-left: 3px !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
  font-weight: 500;
  width: fit-content !important;
}

.button img {
  height: 44px;
  width: 44px;
  padding: 10px;
}

.spanValuesRef {
  font-size: 13px;
  margin-bottom: -2px;
}

.buttonPurchase {
  border: none;
  background-color: #e87722;
  border-radius: 50px;
  padding: 10px;
  color: white;
  font-size: 0.9em;
  font-weight: 400;
  height: 50px;
  width: 270px;
  margin-bottom: 20px;
}

.buttonPurchase:hover {
  background-color: #e97c29f1;
}

button:disabled{
  background-color: #e97c29f1;
  opacity: 50%;
}

.disabledLink{
  pointer-events: none;
}

.containerTotalPrice {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;
  width: 85%;
  padding-top: 15px;
}

.precioTotal {
  color: #0f7bc9;
  font-size: 1.2em;
  font-weight: bold;
}

.precio {
  color: #0f7bc9;
  font-size: 1.2em;
  font-weight: bold;
}

.containerCupon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containerCupon p {
  font-size: 0.9em;
}

.containerCupon p span {
  font-weight: bold;
}

.containerCupon div {
  width: 115%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.containerCupon div input {
  height: 50px;
  width: 80%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: none;
  padding-left: 20px;
}

.containerCupon div input::placeholder {
  padding-left: 15px;
}

.inputButton {
  height: 50px;
  background-color: white;
  border-radius: 50px;
  margin-bottom: 110px;
}

.containerCupon div button {
  height: 50px;
  width: 60%;
  border-radius: 50px;
  font-weight: 500;
  border: none;
  color: white;
  background-color: #0f7bc9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerNetoPrice {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerNetoPriceF {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerCartEmpty {
  width: 55%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textEmptyCart {
  font-size: 1.8em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.521);
}

.containerNoLoged {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerInfoNoLoged {
  border: 2px solid black;
  border-radius: 25px;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
}

.textNoLoged {
  margin-top: 25px;
  font-size: 1.8em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.753);
  margin-bottom: 35px;
}

.backButton {
  width: 250px;
  height: 50px;
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 1.2em;
  font-weight: 500;
  background-color: #0f7bc9;
  margin-top: 35px;
}

.loginButton {
  width: 250px;
  height: 50px;
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 1.2em;
  font-weight: 500;
  background-color: #0f7bc9;
}

.loginButton:hover {
  background-color: #0f7bc9ea;
}

.backButton:hover {
  background-color: #0f7bc9ea;
}

.containerForm {
  display: none;
}

.spanCatalg {
  color: #0d65a3;
  font-size: 14px;
}

.divCatalg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

}
.buttonCatalg {
  background-color: white;
  border: 1px solid #0d65a3;
  color: #0d65a3;
  padding: 10px;
  border-radius: 25px;
  width: 150px;
  font-size: 14px;
}

.buttonCatalg:hover {
  background-color: #0d65a3;
  color: white;
}
.reminderDiv {
  margin-bottom: 150px;
}

.reminder {
  color: #0d65a3;
  font-size: 14px;
  font-weight: lighter;
}

.reminderButton {
  height: 41px;
  background: white;
  color: #0d65a3;
  border-radius: 25px;
  border: 1px solid #0d65a3;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: block;
  width: 152px;
}

.reminderButton:hover {
  background: #0d65a3;
  color: white;
}

.barraLoad {
  width: 50%;
}

.selectDiv {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 20px;
  margin-left: 10px;
}


@media only screen and (max-width: 1300px) {
  .DespachoBotones {
    width: 1024px;
  }
  .containerAll {
    width: 1024px;
  }
  .despacho {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .despacho {
    width: 100%;
  }
  .listAndForm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .containerListProducts {
    margin:0px auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .containerCardData {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 30px;
  }

  .containerDespacho {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .DespachoBotones {
    margin: 0px auto;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
  .despacho {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
  }
  .subTitle {
    border-top: 3px solid #0d65a3;
    width: 100%;
    height: 100%;
    text-align: left;
    font-weight: 450;
    display: flex;
    justify-content: space-between;
  }
  .containerBotones {
    width: 100%;
    height: 100px;
    display:inline;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 2%;
  }
  .botonesa {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .botonesa {
    padding-bottom: 10px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    margin-bottom: 0px;
  }

  .button {
    border: none;
    background-color: inherit;
    color: #e87722;
    font-size: 1.2em;
    font-weight: 900;
   align-items:flex-start;
   text-align: left;
  }

}
@media only screen and (max-width: 827px) {
  .rutas {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 768px) {
 
  .subTitle {
    border-top: 3px solid #0d65a3;
    width: 100%;
    height: 100%;
    font-weight: 450;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .selectDiv {
    display: flex;
    flex-direction: column;

  }

  .selectFecha {
    width: 100%;

  }

  .svgCart{
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .DespachoBotones {
    padding-right: 20px;
    padding-left: 20px;
  }
  .containerAll {
    padding-right: 10px;
    padding-left: 10px;
  }
  .spanBotonesa {
    font-weight: 0.9rem;
  }
  .button {
    font-size: 0.9rem;
    margin-top: -60px;
  }
  
  .button span {
    margin-bottom: 0px;
    font-size: 0.9rem !important;
    
  }
  .button img {
    height: 40px;
    width: 40px;
    padding: 10px;
    margin-bottom: 3px;
    margin-right: -7px;
  }

  
}

@media only screen and (max-width: 500px) {
  .rutas {
    padding-left: 20px;
    
  }
  .textEmptyCart{
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 480px) {

  .toaster{
    margin-top: 155px;
    z-index: 50;
  }

} 

