.divPaginado{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    /* border: solid 1px magenta; */
    width: 70%;
}

.pageNumbers {
    list-style:none;
    margin:0px auto;
   display: flex;
   align-items: center;
   padding-left: 0px;
   
}

.pageNumbers .li{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #EEEEEE; */
    cursor: pointer;
    margin:6px;
    letter-spacing: 2.5px;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 8px 15px rgba(14, 13, 13, 0.1);
    transition: all 0.3s ease 0s;

    font-weight: 600;
}
 .pageNumbers .li:hover{
        background-color: #e68e4b;
        color: white;
 }
 .pageNumbers .li:active {
    background-color: #e68e4b;
    color: white;
}

.pageNumbers .li .btn {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    font-size: large;
    font-weight: 600;
    transition: all 0.3s ease 0s;
}

.pageNumbers .li .btn:hover {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

.disabledLi{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    cursor: default;
    margin:6px;
    letter-spacing: 2.5px;
    border: none;
    border-radius: 50px;
    font-weight: 600;

}

.pageNumbers .disabledLi:hover, .pageNumbers .disabledLi:active{
    background-color: #EEEEEE;
    color: rgba(0, 0, 0, 0.493);
}


.pageNumbers .li:hover {
    background-color: #e68e4b;
    color: white;
}
.pageNumbers .li:active {
background-color: #e68e4b;
color: white;
}

.pageNumbers .disabledLi .btn {
background-color: transparent;
border: none;
color: rgba(0, 0, 0, 0.493);
cursor: default;
font-size: large;
font-weight: 600;
}


@media only screen and (max-width: 425px) {
    .divPaginado{

        width: 60%;
    }
    
    }

