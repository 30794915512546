
.conteinerCarrouselMarcas{
    width: 100%;
    background-color: white;
}
.conteinerCarrouselMarcas h3{
    margin-bottom: 75px;
}

.swiperSlide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .conteinerCarrouselMarcas{
        width: 100vw;
    }
}