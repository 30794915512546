.allHome{
  padding-bottom: 50px;
}

.toaster {
  margin-top: 130px;
  z-index: 50;
}

.filter {
  display: flex;
  width: 22%;
  padding-left: 50px;
  padding-top: 25px;
  margin-top: 50px;
}
.filterChico {
  width: 100%;
}
.order {
  display: flex;
  justify-content: end;
  width: 95%;
  padding-bottom: 50px;
  border-bottom: 2px solid rgb(210, 210, 210);
}
.containerDivCards {
  width: 100%;
  display: flex;
  justify-content: center;
}
.containerCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

/* BUSQUEDA FALSE */
.busquedaFalse {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  color: gray;
}
.busquedaFalse p {
  text-align: start;
}
.imgyP img {
  height: 100px;
  margin-bottom: 40px;
}

.ullista ul li {
  padding: 7px;
  text-align: start;
}

.cards {
  margin-top: 20px;
}

.containerPaginate {
  /* padding-left: 20px; */
  padding-top: 50px;
}

.containerNoProducts {
  display: flex;
  width: 80%;
}

.containerLoading {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerAll {
  display: flex;
}

.containerCardPaginate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1440px) {
  .containerPaginate {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .filter {
    display: flex;
    width: 22%;
    padding-left: 5px;
    padding-top: 25px;
    margin-top: 50px;
  }
  .order {
    width: 90%;
  }
  .cards {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .containerCards{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 921px) {
  .containerCards {
    gap: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .containerAll {
    flex-direction: column;
    align-items: center;
  }

  .filter {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    justify-content: space-evenly;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 2px solid rgb(210, 210, 210);
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .toaster {
    margin-top: 150px;
  }
}
@media only screen and (max-width: 425px) {
  .containerCards {
    gap: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .cards {
    display: flex;
    justify-content: center;
  }
  .containerCards {
    gap: 10px;
  }
  .filter {
    justify-content: space-around;
  }

  .busquedaFalse {
    margin-top: -200px;
    font-size: 15px;
  }
  .imgyP img {
    height: 80px;
  }
}
