.labelOr {
  padding-bottom: 20px;
}

.selectorCont {
  width: 200px;
}

.selector {
  width: 100%;
  padding: 10px;
  border: 1px solid #0D64A3;
  border-radius: 20px;
  -webkit-appearance: none;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: transparent;
}

.selectorItem {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.orderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activeOrder {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: fit-content;
}

.txtactiveorder {
  margin-top: 10px;
  font-weight: 600;

}

.titleactiveorder {
  color: #0D64A3;
  border-bottom: 2px solid #74a3c5;
}

@media only screen and (max-width: 768px) {
  .selectorCont {
    margin-top: 30px;
  }

}

@media only screen and (max-width: 425px) {
.orderContainer{
  margin-right: -30px;
}
  .selector {
    width: 70%;
    padding: 5px;
  }

}