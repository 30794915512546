.background {
  z-index: 999999999999;
  background-color: rgba(0, 0, 0, 0.407);
  /* background-color: white; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;
  /* margin-top: 90px; */
}

.ring {
    display: inline-block;
    width: 150px;
    height: 150px;
    padding-top: 20px;
  }
  .ring:after {
    content: " ";
    display: block;
    width: 150px;
    height: 150px;
    /* margin: 8px; */
    border-radius: 50%;
    /* border: 6px solid #a4a4a4;
    border-color: #a4a4a4 transparent #a4a4a4 transparent; */
    border: 6px solid white;
    border-color: white transparent white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.logo{
  top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    overflow: hidden; 
    width: 120px;
    padding-bottom: 20px;
}

.procesando{
    padding-top: 40px;
    /* color: #a4a4a4; */
    color: white;
}
