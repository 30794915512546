.containerCard {
  width: 280px;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  background-color: white;
}
.containerCardFilter {
  width: 280px;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  background-color: white;

}
.containerCardFilter:hover {
  transition: 0.3s;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
}
.containerCard:hover {
  transition: 0.3s;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
}

@supports (object-fit: cover) {
  .containerImg img {
    width: 80%;
    height: 190px;
    object-fit: contain;
    object-position: center center;
    cursor: pointer;
  }
}

.img {
  width: 100%;
}

.img:hover {
  filter: brightness(1.15);
}

.info {
  width: 90%;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  color: #000;
  font-size: 0.9rem;
  font-weight: 450;
  text-align: left;
}

.netoTxt {
  color: #000;
  font-size: 0.8rem;
  font-weight: 450;
}

.netoPrice {
  color: #e87722;
  font-size: 1.5rem;
  font-weight: 600;
}

.productDescription {
  color: #000;
  font-size: 0.7rem;
  font-weight: 450;
  text-align: left;
}

.unidadTxt {
  color: #000;
  font-size: 0.8rem;
  font-weight: 450;
  margin-top: 8px;
}

.unidadTxts {
  color: #000;
  font-size: 0.8rem;
  font-weight: 450;
}

.unidadTxts2{
  color: rgba(0, 0, 0, 0.493);
  font-size: 0.7rem;
  font-weight: 450;
}

.priceUnidad {
  font-size: 0.9rem;
  font-weight: 600;

}

.sinDescuentoTachado {
  color: rgba(0, 0, 0, 0.493);
  font-size: 0.9rem;
  font-weight: 450;
  text-decoration: line-through;
}

.tachadoUnidad {
  color: rgba(0, 0, 0, 0.493);
  font-size: 0.9rem;
  font-weight: 450;
  text-decoration: line-through;
}

.containerButtons {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.containerAdd {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 80px;
  border: 1px solid #e27725;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
}

.minus {
  width: 20px;
  cursor: pointer;
}

.plus {
  width: 20px;
  cursor: pointer;
}

.quantity {
  max-width: 40px;
  min-width: 20px;
  width: 30px;
  font-size: 20px;
  color: #e87722;
  font-weight: 700;
  text-align: center;
  outline: none;
  border: none;
}
.quantity:focus {
border: 1px solid #e87722;
border-radius: 5px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.buttonAdd {
  background-color: #e87722;
  border: none;
  padding-top: 3px;
  padding-bottom: 6px;
  width: 120px;
  border-radius: 20px;
  color: white;
  font-size: large;
  cursor: pointer;
}

.cucardaImg{
  height: 25px !important;
  width: auto !important;
  position: absolute;
  margin-top: 7px !important;
  margin-left: -10px !important;
  z-index: 99;
}


.productNotAvailable{
  color: rgb(160, 158, 158);

}
@media only screen and (min-width: 2561px) {
  .containerCard {
    width: 300px;
  }
  .containerCardFilter {
    width: 300px;
  }
}

@media only screen and (max-width: 2560px) {
  .containerCard {
    width: 300px;
  }
  .containerCardFilter {
    width: 300px;
  }
}

@media only screen and (max-width: 1399px) {
  .containerCard {
    width: 255px;
  }
  .containerCardFilter {
    width: 255px;
  }
}

@media only screen and (max-width: 1199px) {
  .containerCard {
    width: 210px;
  }
  .containerCardFilter {
    width: 210px;
  }
  .containerButtons {
    gap: 5px;
  }

  @supports (object-fit: cover) {
    .containerImg img {
      height: 170px;
    }
  }

  .title {
    font-size: 0.87rem;
  }

  .netoTxt {
    font-size: 0.75rem;
  }

  .netoPrice {
    font-size: 1.3rem;
  }

  .productDescription {
    font-size: 0.7rem;
  }

  .unidadTxt {
    font-size: 0.75rem;
  }

  .unidadTxts {
    font-size: 0.75rem;
  }

  .priceUnidad {
    font-size: 0.85rem;
  }

  .sinDescuentoTachado {
    font-size: 0.85rem;
  }

  .tachadoUnidad {
    font-size: 0.85rem;
  }

  .buttonAdd {
    width: 100px;
  }
}
@media only screen and (max-width: 1180px) {
  .containerCardFilter {
    width: 200px;
  }
}

@media only screen and (max-width: 991px) {
  .containerCardFilter {
    width: 330px;
  }
  .containerCard {
    width: 330px;
  }
  .containerButtons {
    gap: 20px;
  }

  .buttonAdd {
    width: 120px;
  }
}
@media only screen and (max-width: 916px) {
  .containerCardFilter{
    width: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .containerCardFilter {
    width: 240px;
    height: 370px;
  }
  .containerCard {
    height: 370px;
    width: 240px;
  }

  @supports (object-fit: cover) {
    .containerImg img {
      height: 180px;
      margin-top: -10px;
    }
  }
  .title {
    color: #000;
    font-size: 0.9rem;
    font-weight: 450;
    text-align: left;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .netoTxt {
    font-size: 0.8rem;
  }

  .netoPrice {
    font-size: 1.4rem;
  }

  .productDescription {
    font-size: 0.7rem;
  }

  .unidadTxt {
    font-size: 0.8rem;
  }

  .unidadTxts {
    font-size: 0.8rem;
  }

  .priceUnidad {
    font-size: 0.9rem;
  }

  .sinDescuentoTachado {
    font-size: 0.9rem;
  }

  .tachadoUnidad {
    font-size: 0.9rem;
  }
  .productDescription {
    color: #000;
    font-size: 0.7rem;
    font-weight: 450;
    text-align: left;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 618px) {
}

@media only screen and (max-width: 538px) {
  .containerCardFilter {
    width: 230px;
  }
  .containerCard {
    width: 230px;
  }
  .title {
    width: 210px;
  }
  .info {
    height: 200px;
  }

  .containerButtons {
    height: 70px;
  }
}

@media only screen and (max-width: 517px) {
  .containerCardFilter {
    width: 210px;
  }
  .containerCard {
    width: 210px;
  }
  .title {
    width: 190px;
  }
  .buttonAdd {
    width: 100px;
  }

  .containerButtons {
    gap: 10px;
  }
}

@media only screen and (max-width: 477px) {
  .containerCardFilter {
    width: 200px;
  }
  .containerCard {
    width: 200px;
  }
  .title {
    width: 180px;
  }
}

@media only screen and (max-width: 458px) {
  .containerCardFilter {
    width: 190px;
  }
  .containerCard {
    width: 190px;
  }
  .title {
    width: 170px;
  }

  .containerButtons {
    gap: 5px;
  }
}

@media only screen and (max-width: 437px) {
  .containerCardFilter {
    width: 183px;
  }
  .containerCard {
    width: 183px;
  }
  .title {
    width: 163px;
  }
  .buttonAdd {
    width: 90px;
  }
  .containerButtons {
    gap: 5px;
  }
}

@media only screen and (max-width: 425px) {
  .info {
    height: 200px;
  }

  .containerButtons {
    height: 70px;
    gap: 5px;
  }

  .buttonAdd {
    width: 80px;
    font-size: 13px;
  }

  .containerAdd {
    padding-top: 1px;
    padding-bottom: 1px;
    width: 60px;
    height: 29px;
  }

  .minus,
  .plus {
    font-size: 14px;
    width:13px;

  }


  .quantity {
    font-size: 14px;
    width:30px;

  }
}

@media only screen and (max-width: 400px) {
  .containerCardFilter {
    width: 170px;
    height: 365px;
  }
  .containerCard {
    height: 365px;
    width: 170px;
  }
  .title {
    width: 153px;
  }
}

@media only screen and (max-width: 375px) {
  @supports (object-fit: cover) {
    .containerImg img {
      height: 180px;
      width: 70%;
      margin-top: -17px;
    }
  }
  .containerCardFilter {
    width: 157px;
    height: 360px;
  }
  .containerCard {
    height: 360px;
    width: 157px;
  }
  .buttonAdd {
    width: 80px;
  }

  .containerAdd {
    width: 50px;
  }
  .minus,
  .plus {
    font-size: 13px;
    width: 18px;
  }

  .quantity {
    font-size: 14px;
    width:30px;

  }
}
@media only screen and (max-width: 376px) {
  .containerCardFilter {
    width: 157px;
    height: 360px;
  }
  .containerCard {
    height: 360px;
    width: 157px;
  }
  .title {
    width: 140px;
  }
  .minus,
  .plus {
    font-size: 13px;
    width: 12px;
  }
}
@media only screen and (max-width: 351px) {
  .containerCardFilter {
    width: 150px;
    height: 360px;
  }
  .containerCard {
    height: 360px;
    width: 150px;
  }
  .title {
    width: 135px;
  }
}

@media only screen and (max-width: 336px) {
  .containerCardFilter {
    width: 100%;
    height: 360px;
  }
  .containerCard {
    height: 360px;
    width: 100%;
  }
  .title {
    width: 100%;
   
  }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}