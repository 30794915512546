.containerFooter {
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  background: #262626;
}

html {
  min-height: 100%;
  width: 100%;
  position: absolute;
}

.infoFooter {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 6em;
  padding-bottom: 6em;
}

.containerLogCopy{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.contData{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

}

.contData2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.lista2{
  margin-top: 10px;
  padding: 0;
}

.containerInfo2 {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
}

.infoFooter2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleF2 {
  font-size: 14px;
  font-weight: bold;
  color: #e96d19;
}

.lista{
  padding: 0;
}
.redesSoc{
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.title1 {
  font-size: 14px;
  font-weight: bold;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
  color: #afb08f;
  font-size: 13px;
}

.imgBtn {
  width: 25px;
  height: 25px;
}

.imgBtn:hover {
  filter: brightness(1.5);
}

.imgBtn2 {
  width: 30px;
  height: 30px;
}

.imgBtn2:hover {
  filter: brightness(1.5);
}


.infoFooter3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoFooter4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerPie {
  background: #e96d19;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid white;
}

.span {
  font-size: 13px;
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 100;
}

.copyright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerLogo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.frase {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aLink{
  text-decoration: none;
  color: inherit;
}

.aLink:hover{
  color: inherit;
}

/* RESPONSIVE TABLET */
@media only screen and (max-width: 1024px) {
 .span{
  font-size: 9px;
 }

 .title1{
  font-size: 10px;
 }

 .text1{
  font-size: 9px;
 }

 .imgLogo{
  width: 85%;
 }

 .imgBtn{
  width: 20px;
  height: 20px;
 }
 .imgBtn2{
  width: 20px;
  height: 20px;
 }
}

@media only screen and (max-width: 768px) {
  .contData2{
    align-items: flex-start;
  
  }

  .infoFooter{
    flex-direction: column;
    padding-left: 50px;
  }

  .span{
    font-size: 10px;
   }
  
   .title1{
    font-size: 14px;
   }

   .text1{
    font-size: 13px;
   }
   .imgLogo{
    width: 250px;
   }

   .imgBtn{
    width: 25px;
    height: 25px;
   }
   .imgBtn2{
    width: 30px;
    height: 30px;
   }
   .copyright{
    justify-content: flex-start;
   }

   .containerLogCopy{
    margin-bottom: 75px;
   }
}


@media only screen and (max-width: 425px) {
  .infoFooter{
    padding-left: 20px;
  }

}