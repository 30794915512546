.sOfertastxt {
    color: #0D64A3;
    border-bottom: 2px solid #74a3c5;
    width: 10rem;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
  }
  .sOfertastxt2 {
    color: #0D64A3;
    width: 10rem;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
    cursor: pointer;
  }
  .checkboxOfertas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
  }
  .checkboxOfertas2{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .checkboxOfertas div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 10px;
  }


.inputt[type=radio] {
    appearance: none;
    -webkit-appearance: none;
    height: 17px;
    width: 17px;
    border: 2px solid #fd7e14;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .inputt[type=radio]:after {
    content: "\2713";
    display: none;
  }
  
  .inputt[type=radio]:checked:after {
    display: block;
  }
  
  .checkboxOfertas div label {
    font-size: 16px;
    width: 175px ;
    text-align: left;
  }
  .checkboxOfertas2 label {
    font-size: 16px;
    width: 175px ;
    text-align: left;
  }
  .buttonFiltrarContainer{
    display: flex;

  }
  .buttonFiltrar{
    border: none;
    border-radius: 20px;
    background-color: #fd7e14;
    padding: 8px;
    margin-top: 20px;
    width: 100px;
    color: white;
  }
/* filtros */
  .filtrosAplicados{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .filtrosAplicados2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .sOfertastxtFiltros{
    color: #0D64A3;
    border-bottom: 2px solid #74a3c5;
    width: 10rem;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .deleteFilter{
    border:none;
    background-color: transparent;
  }
  .spinnerContainer{
    display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: -60px;
  height: 10%;
  }
.spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem !important;
  height: 3rem !important;
}

  @media only screen and (max-width: 768px) {
    .spinnerContainer{
      margin-left: 0px;
      height: 100%;
    }
.formnotMedia{
  display: none;
}
 .filterC {
    margin-top: 110px;
    
  } 
  .filtrosAplicados2{
    margin-top: -10px;
margin-bottom: -87px;
  }
.filtrosAplicados{
margin-top: -10px;
margin-bottom: -60px;
}
  .butonModal {
    
    width: 150px;
    color: black !important;
    background-color: transparent !important;
    padding: 11px !important;
    border-radius: 20px !important;
    border: 1px solid #0D64A3 !important;
    font-size: 16px !important;
    z-index: 1;
  }

  .modalFilter {
    padding: 20px;
  }

  }
  @media only screen and (max-width: 425px) {
    .butonModal{
      
width: 120px;
padding: 6px !important;
    }
    
      }