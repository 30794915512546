.containerBreadCrumb{
    padding-top: 20px;
    padding-left: 20px;
}

.logoCasa{
    font-size: 27px;
    color: black !important;
    fill: black !important;
    padding-bottom: 5px;
}

.noActive{
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.active{
    height: 40px;
    display: flex;
    align-items: center;
    color: black;
    font-weight: 600;
    font-size: 12px;
}

.link{
    text-decoration: none;
    width: 100%;
    color: black;
}

.link:hover{
    color: black;
}


@media only screen and (max-width: 425px) {       

    .containerBreadCrumb{
        padding-left: 10px;
    }

}



