.tablaVolumenDescContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: large;
    padding-top: 50px;
    padding-bottom: 50px;
}
.tablaVolumenDescH2{
    margin-bottom: 50px;
}
.tablaVolumenDesc{
    
    border-collapse: separate;
    border-radius: 1rem;
    border: 2px solid  #0D64A3;
    border-top: 30px solid  #0D64A3;
    border-spacing: 0;
    width: 30vw;
}

.tablaVolTr{
    height: 70px;
}
.tablaVolTr1{
    height: 70px;
    background-color: #e8f2f8;

}
.tablaVolTr2{
    height: 70px;
    background-color: #e8f2f8;

}
.tablaVolumenDesc td{
    border-top: 2px solid  #0D64A3;

}
.containerLoading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    padding-top: 75px;

  }
@media screen and (max-width: 1366px) {
    .tablaVolumenDesc{
        width: 35vw;
    }
}

@media screen and (max-width: 1271px) {
    .containerLoading{
        padding-top: 50px;
    
      }
    .tablaVolumenDescContainer{
        padding-top: 30px;
    }
}

@media screen and (max-width: 1024px) {
    .tablaVolumenDesc{
        width: 45vw;
    }
}

@media screen and (max-width: 768px) {
    .tablaVolumenDesc{
        width: 60vw;
    }
}


@media screen and (max-width: 480px) {
    .tablaVolumenDescH2{
        margin-right: 3vw;
    }
    .tablaVolumenDescContainer{
        
        font-size: small;
    }
    .tablaVolumenDesc{
        width: 85vw;
        margin-right: 0px;
    }
    
}
@media screen and (max-width: 424px) {
    .tablaVolumenDescH2{
        margin-right: 0px;
        width: 90vw;
    }
    .tablaVolumenDesc{
        width: 90vw;
        margin-right: 0px;
    }    
}

@media screen and (max-width: 375px) {
    .tablaVolumenDescH2{
        margin-right: 0px;
        width: 90vw;
    }
    .tablaVolumenDesc{
        width: 90vw;
        margin-right: 0px;
    }    
}