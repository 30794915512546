.containerLoading{
    position: absolute ;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.product_notFound{
    margin-top: 10vh;
    height: 100%;
  }
  .containerNoProducts{
    width: 100%;
  }

  .busquedaFalse{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: gray;
    
  }
  .busquedaFalse p{
   text-align: start;
  }

  .imgyP img{
    height: 100px;
    margin-bottom: 40px;
  }
  .ullista ul li{
    padding: 7px;
    text-align: start;
    }
    @media only screen and (max-width: 425px) {       
      .busquedaFalse{
          font-size: 15px;
      }
      .imgyP img{
    
          height: 80px;
      }
    }