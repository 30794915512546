.containerHomepage {
  background-color: #fcf0e7;
  padding-top: 60px;
}

.containerHomePageFix{
  background-color: #fcf0e7;
  padding-top: 60px;
}

.containerNuestrasMarcas{
    display: flex;
    align-items: center;
    justify-content: center;
}


.toaster{
  margin-top: 130px;
  z-index: 50;
}

@media only screen and (max-width: 768px) {  

  .containerHomepage {
    padding-top: 40px;
  }

  .containerHomePageFix{
    padding-top: 40px;
  }

}


@media only screen and (max-width: 480px) {  

  .toaster{
    margin-top: 150px;
  }

}

@media only screen and (max-width: 425px) {  

  .containerHomepage {
    padding-top: 70px;
  }

}
