.toaster {
  margin-top: 130px;
  z-index: 50;
}

.containerDetail {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;
  /* padding-top: 100px;
  border: solid 1px red; */
}
.containerForm {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info {
  text-align: left;
  margin-left: 50px;
  margin-right: 100px;
  margin-bottom: 10px;
  margin-top: -30px;
  height: 473px;
  width: 430px;
}
.product_notFound {
  height: 100%;
  margin-bottom: 100px;
}
.containerNoProducts {
  width: 100%;
}
.containerLoading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.busquedaFalse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: gray;
}
.busquedaFalse p {
  text-align: start;
}
.imgyP img {
  height: 100px;
  margin-bottom: 40px;
}

.ullista ul li {
  padding: 7px;
  text-align: start;
}
.containerGalery {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: solid 1px red; */
}
.img {
  display: block;
  cursor: pointer;
}

.container .selected {
  margin: auto;
  border-radius: 20px;
  width: 475px;
  margin: 0 auto;
  display: inline-block;
  /* border: solid 1px red; */
}

.container .imgContainer {
  padding: 20px 0 0 0;
  /* border: solid 1px red; */
}

.container .cucardaDiscount {
  width: 150px;
}

.imgDpv {
  width: 100%;
}

.scroll {
  overflow: auto;
}

.scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  display: flex;
  background-color: rgb(207, 204, 204);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #e87722;
  border-radius: 10px;
}

.container .imgContainer img {
  width: 130px;
  height: 130px;
  margin: 15px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.title {
  font-size: large;
  font-weight: 450;
  font-size: xx-large;
  font-weight: 700;
}

.gs {
  font-size: medium;
  font-weight: 400;
  margin-bottom: 5px;
}
.oferta_vigente {
  margin-top: 15px;
  font-size: medium;
  font-weight: bold;
  color: #e27725;
}
.texto_info_cardDetail {
  margin-top: 20px;
}
.description {
  font-size: large;
  font-weight: 400;
  color: gray;
}

.price {
  margin-left: 10px;
  color: #e87722;
  font-size: x-large;
  font-weight: bold;
}
.price2 {
  margin-top: 10px;
  margin-left: 10px;
  font-size: x-large;
  font-weight: bold;
}
.price3 {
  margin-left: 10px;
  font-size: x-large;
  font-weight: bold;
  text-decoration: line-through;
}
.discountPercentage {
  color: #18c468;
  font-weight: bold;
  margin-left: 10px;
}
.button {
  background-color: #e87722;
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 180px;
  border-radius: 20px;
  color: white;
  font-size: large;
  cursor: pointer;
}

.button:hover {
  background-color: #e68e4b;
  transition: 0.3s ease;
}

.buttonNoStock {
  background-color: rgba(128, 128, 128, 0.616);
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 160px;
  border-radius: 20px;
  color: white;
  font-size: large;
  margin-bottom: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.buttonNoStock:hover {
  background-color: rgba(128, 128, 128, 0.466);
  transition: 0.3s ease;
}

.code {
  color: gray;
}

.box {
  display: inline-block;
}

.despacho {
  font-size: large;
  font-weight: 400;
  color: #0d64a3;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.precios {
  margin-top: 30px;
}

.containerStock {
  height: 50px;
  width: 100%;
  padding-top: 20px;
}

.containerStock span {
  border-radius: 50px;
  padding: 7px;
  margin: 0;
}

.stockBajo {
  color: #e41d1d;
  background-color: #fde9ea;
}

.stock {
  color: #18c468;
  background-color: #e9fdea;
}

.containerLoading {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerAdd {
  padding-top: 6px;
  padding-bottom: 6px;
  width: 90px;
  border: 1px solid #e27725;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.minus {
  width: 20px;
  cursor: pointer;
}

.plus {
  width: 20px;
  cursor: pointer;
}

.quantity {
  font-size: 20px;
  color: #e87722;
  font-weight: 700;
}
.containerBut {
  margin-left: -15px;
  margin-top: 20px;
  display: inline;
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.productNotAvailable{
  color: rgb(160, 158, 158);
}

@media screen and (min-width: 2561px) {
  .containerForm {
    height: 100%;
  }
}

@media screen and (max-width: 2560px) {
  .containerForm {
    height: 100%;
  }
  .container .imgContainer {
    width: 160px;
    flex-wrap: wrap;
    padding: 20px 0 0 0;
    display: inline-block;
    float: left;
  }

  .scroll {
    overflow: auto;
    height: 450px;
    width: 170px;
    direction: rtl;
    float: left;
    margin-right: 15px;
  }
  .container .cucardaDiscount {
    width: 150px;
    position: absolute;
    z-index: 9;
  }
}
@media screen and (max-width: 1440px) {
  .containerForm {
    height: 100%;
  }
  .container .imgContainer {
    width: 160px;
    flex-wrap: wrap;
    padding: 20px 0 0 0;
    display: inline-block;
    float: left;
  }

  .scroll {
    overflow: auto;
    height: 450px;
    width: 170px;
    direction: rtl;
    float: left;
    margin-right: 15px;
  }

  .container .cucardaDiscount {
    width: 150px;
    position: absolute;
    z-index: 9;
  }

}

@media screen and (max-width: 1366px) {
  .containerForm {
    height: 100%;
  }
}

@media screen and (max-width: 1271px) {
  .containerForm {
    padding-top: 20px;
    width: auto;
  }

  .info {
    margin-right: 30px;
    margin-top: -120px;
  }

  .container .selected {
    width: 400px;
    height: 400px;
    margin-top: 100px;
  }

  .containerGalery {
    margin-left: 30px;
    width: 400px;
  }

  .container .imgContainer {
    white-space: nowrap;
    justify-content: flex-end;
  }

  .scroll {
    height: 160px;
    width: 400px;
    display: flex;
    transform: rotateY(180deg);
  }

  .container .imgContainer img {
    width: 110px;
    height: 110px;
    margin-top: 0px;
    display: inline-block;
    text-align: center;
    transform: rotateY(180deg);
  }
}

@media screen and (max-width: 768px) {
  .despacho {
    width: 70%;
  }
  .containerForm {
    padding-top: 20px;
    width: auto;
  }

  .info {
    margin-left: 30px;
    margin-right: 15px;
    margin-top: -120px;
  }

  .container .selected {
    width: 400px;
    height: 400px;
  }

  .containerGalery {
    margin-left: 30px;
    width: 400px;
  }

  .container .imgContainer {
    white-space: nowrap;
    justify-content: flex-end;
  }

  .scroll {
    height: 160px;
    width: 400px;
    display: flex;
    transform: rotateY(180deg);
  }

  .container .imgContainer img {
    width: 110px;
    height: 110px;
    margin-top: 0px;
    display: inline-block;
    text-align: center;
    transform: rotateY(180deg);
  }
}

@media screen and (max-width: 767px) {
  .containerForm {
    padding-top: 20px;
    width: auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .info {
    margin-left: 25px;
    margin-right: 0px;
    margin-top: -120px;
    width: 360px;
    height: 100%;
  }

  .container .selected {
    width: 350px;
    height: 350px;
    margin-top: 0px;
  }

  .containerGalery {
    margin-bottom: 150px;
    margin-left: 0px;
  }

  .container .imgContainer {
    white-space: nowrap;
    justify-content: flex-end;
    margin-top: 0px;
  }

  .scroll {
    height: 150px;
    width: 350px;
    display: flex;
    transform: rotateY(180deg);
    margin-left: 28px;
  }

  .container .imgContainer img {
    width: 100px;
    height: 100px;
    margin-top: 5px;
    margin: 8px;
    display: inline-block;
    text-align: center;
    transform: rotateY(180deg);
  }


  .container .cucardaDiscount {
    width: 150px;
    position: absolute;
    z-index: 9;
    margin-left: 13px;
  }
}

@media only screen and (max-width: 500px) {
  .containerForm {
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* border: solid 1px red; */
  }

  .containerGalery {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border: solid 1px red; */
    /* margin-top: 0px; */
  }

  .container .selected {
    margin: auto;
    border-radius: 20px;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    display: inline-block;
    /* border: solid 1px red; */
  }

  .container .imgContainer {
    padding: 0px 0 0 0;
  }


  .container .cucardaDiscount {
    width: 150px;
    position: absolute;
    z-index: 9;
    margin-left: 37px;
  }

}

@media only screen and (max-width: 480px) {
  .toaster {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 425px) {
  .busquedaFalse {
    font-size: 15px;
  }
  .imgyP img {
    height: 80px;
  }
  .title {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 375px) {
  .containerForm {
    padding-top: 20px;
    width: auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .info {
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: -120px;
    width: 330px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .container .selected {
    width: 93%;
  }

  .containerGalery {
    margin-bottom: 150px;
    display: inline-block;
    width: 350px;
  }

  .container .imgContainer {
    white-space: nowrap;
    justify-content: flex-end;
    margin-top: 0px;
  }

  .container .cucardaDiscount {
    width: 150px;
    position: absolute;
    z-index: 9;
    margin-left: 10px;
  }

  .imgDpv {
    width: 100%;
  }

  .scroll {
    height: 150px;
    width: 350px;
    display: flex;
    transform: rotateY(180deg);
    margin-left: 0px;
  }

  .container .imgContainer img {
    width: 100px;
    height: 100px;
    margin-top: 5px;
    margin: 8px;
    display: inline-block;
    text-align: center;
    transform: rotateY(180deg);
  }

  .containerGalery .container{
    align-items: start;
    justify-content: start;
  }
}

@media only screen and (max-width: 336px) {
  .button {
    width: 150px;
  }

  .texto_info_cardDetail {
    font-size: 15px;
  }

  .container .cucardaDiscount {
    width: 120px;
    position: absolute;
    z-index: 9;

  }

  .containerGalery .container{
    align-items: start;
    justify-content: start;
  }

  .imgDpv {
    width: 100%;
  }
}
