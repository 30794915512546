:root{
  --widthlogo:15%

}

.ghostHeader{
  width: 100%;
  height: 90px;
}

.headerContainer {
  width: 100%;
  display: flex;
  background-color: #e96d19;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 1000;
  
}

.containerLogo {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* BUSCADOR */
.containerBuscador {
  /* margin-top: 285px; */
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column; /* NUEVO */
  justify-content: center;
  align-items: center;
  
}

.containerButtons {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.containerLogout{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}

.containerIconLogout{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #f8f9fa;
  cursor: pointer;
}

.containerIconLogout:hover{
  filter: brightness(2);
}

.iconLogout{
  color: #0d64a3;
}

.idStrong{
  font-weight: bold;
}

/* LOGIN-CARRITO */
.loginCartContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  margin-top: 20px;
  width: 100%;
}

.userBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contCartQP{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.cartBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55px;
  height: 60px;
}

.cartValue{
  color: #fff;
  font-weight: 400;
}

.logCart {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ayudaBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
}
.ayudaBtn:hover {
  filter: brightness(2);
}
.text {
  padding-top: 0px;
  color: #f8f9fa;
  font-size: 15px;
  margin-left: 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}


.a {
  border: 0;
  text-decoration: none;
}

.imgBtn {
  width: 40px;
  height: 40px;
}

.imgBtn:hover {
  filter: brightness(2);
}

.menuBurger {
  display: none;
}

.totalUnitCart{
  background-color: #0d64a3;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #fff;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  right: 0;
}

.direccion{
 display: none;
}

/* RESPONSIVE */
/* LAPTOP 1440PX */
@media only screen and (max-width: 1440px) {
  /* DROPDOWN */
  .result {
    left: 229px;
  }
}

@media only screen and (max-width: 1400px) {
 .logo img{
  width: 160px;
 }
}

@media only screen and (max-width: 1280px) {
  .logo img{
   width: 150px;
  }
 }

 @media only screen and (max-width: 1140px) {
  .logo img{
   width: 150px;
  }
 }

/* LAPTOP 1024px */

@media only screen and (max-width: 1024px) {
  .cartValue{
    display: none;
  }
  
  .menuBurger {
    display: none;
  }
  .logo img {
    width: 170px;
    height: 170px;
    margin-left: 30px;
  }

  .text {
    padding-top: 0px;
    color: #f8f9fa;
    font-size: 14px;
    margin-left: 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    display: flex;
  }

  .imgBtn {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 980px) {
  .logo img{
   width: 140px;
  }
 }

 @media only screen and (max-width: 890px) {
  .logo img{
   width: 140px;
  }
 }

/* TABLET 768px */

@media only screen and (max-width: 768px) {

  .direccion{
    height: 25px;
    width: 100%;
    top: 0;
    background-color: #313131;
    position: fixed;
    z-index: 1000;
    color: white;
    font-size: 13px;
    align-items: center;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-top: 3px;
  }

  .headerContainer {
    width: 100%;
    display: flex;
    background-color: #e96d19;
    justify-content: center;
    align-items: center;
    height: 75px;
    position: fixed;
    top: 25px;
    z-index: 1000;
  }
  .ghostHeader{
    height: 100px;
  }

  .menuBurger {
    display: flex;
  }
  .btnMenuBurger {
    border: none;
    background-color: transparent;
  }
  .menuBurger svg {
    width: 40px;
    margin-left: 10px;
  }
  .containerLogo {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    width: 160px;
    height: 80px;
  }
  
  .containerButtons {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* LOGIN-CARRITO */
  .loginCartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    width: 100%;
  }

  .userBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cartBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logCart {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ayudaBtn {
    display: none;
  }
  .ayudaBtn:hover {
    filter: brightness(2);
  }

  .text:hover {
    border-bottom: 2px solid white;
  }

  .a {
    border: 0;
    text-decoration: none;
  }

  .imgBtn {
    width: 40px;
    height: 40px;
  }

  .imgBtn:hover {
    filter: brightness(2);
  }

  .logoUser {
    display: none;
  }
}

/* MOBILE L 425px */

@media only screen and (max-width: 767px) {
  .containerLogout{
    display: none;
  }
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .menuBurger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
  }
  .btnMenuBurger {
    border: none;
    background-color: transparent;
  }
  .menuBurger svg {
    width: 40px;
  }
  .containerLogo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    width: 180px;
    height: 80px;
  }
  .containerBuscador {
    display: none;
  }

  .containerButtons {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* LOGIN-CARRITO */
  .loginCartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    width: 100%;
  }

  .userBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cartBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logCart {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoUser {
    display: none;
  }
}

/* MOBILE M 375px */

@media only screen and (max-width: 375px) {
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .menuBurger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
  }
  .btnMenuBurger {
    border: none;
    background-color: transparent;
  }
  .menuBurger svg {
    width: 37px;
  }
  .containerLogo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .logo img {
    width: 170px;
    height: 80px;
  }
  .containerBuscador {
    display: none;
  }

  .containerButtons {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* LOGIN-CARRITO */
  .loginCartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    width: 100%;
  }

  .userBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cartBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logCart {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoUser {
    display: none;
  }
}
