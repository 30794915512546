.container {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-bottom: 50px;
}

.container2 {
  margin: 0px auto;
  align-items: center;
  text-align: center;
  width: 810px;
  padding-top: 40px;
}

.icon {
  width: 50px;
  color: #2a65a3;
}

.title {
  color: #2a65a3;
  font-size: 28px;
  font-weight: 500;
}

.subtitle {
  font-size: 23px;
  font-weight: 500;
}

.subtitleBlue {
  color: #2a65a3;
  font-size: 23px;
  font-weight: 500;
}

.subtitle2 {
  font-size: 18px;
  font-weight: 500;
}

.subtitleBlue2 {
  color: #2a65a3;
  font-size: 18px;
  font-weight: 500;
}

.contPedido {
  margin-top: 15px;
}

.contNumero {
  margin-top: -7px;
}

.textBold {
  font-weight: 600;
}

.red {
  color: #e24d45;
}

.contComunicarte {
  padding-top: 20px;
  text-align: left;
  width: 50%;
  margin: 0px auto;
  font-size: 15px;
  color: #707070;
  padding-bottom: 30px;
}

.textBlue {
  color: #2a65a3;
  padding-bottom: 5px;
}

.contResumen {
  width: 76%;
  border: solid 2px #c1c0c0;
  border-radius: 30px;
  margin: 0px auto;
}

.titleResumen {
  color: #2a65a3;
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  display: grid;
  align-content: center;
  justify-content: center;
}

.contNroPagar {
  display: flex;
  justify-content: space-between;
}

.nro {
  width: 50%;
  align-items: center;
  text-align: center;
  padding: 35px;
}

.pagar {
  width: 50%;
  align-items: center;
  text-align: center;
  background-color: #fcf1e9;
  padding: 35px;
  border-bottom-right-radius: 30px;
}

.padd {
  padding-bottom: 10px;
}

.subtOrange {
  font-size: 23px;
  font-weight: 700;
  color: #e67722;
}

.miniText {
  font-size: 15px;
  color: #707070;
}

.ahorraste {
  font-size: 20px;
}

.ahorrasteBlue {
  font-size: 20px;
  color: #2a65a3;
  font-weight: 700;
}

.padd2 {
  padding-top: 20px;
  padding-bottom: 30px;
}

.button {
  margin: 0px auto;
  width: 25%;
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: left;
  text-align: center;
  border-radius: 30px;
  background-color: #2a65a3;
  border: solid #2a65a3;
  color: white;
  margin-bottom: 20px;
}

.button:hover {
  transition: 1s;
  background-color: white;
  border: solid #2a65a3;
  color: #2a65a3;
}

.inicio {
  color: #e67722;
}

.inicio:hover {
  color: #e67722;
}

.inicio:selected {
  color: #e67722;
}

@media only screen and (max-width: 810px) {
  .container2 {
    width: 767px;
  }
}

@media only screen and (max-width: 768px) {
  .container2 {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .titleResumen {
    margin-top: 10px;
    margin-bottom: -20px;
  }
  .contNroPagar {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .nro {
    width: 100%;
  }

  .pagar {
    width: 100%;
    border-bottom-left-radius: 30px;
  }
  .contResumen {
    width: 100%;
  }
  .button {
    width: 190px;
  }
}

@media only screen and (max-width: 732px) {
  .contComunicarte {
    width: 330px;
  }
}
@media only screen and (max-width: 705px) {
  .container2 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .contResumen {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .contComunicarte {
    width: 260px;
  }
}
