.ghostMenu{
  width: 100%;
  height: 40px;
}

.categoriesContainer {
  background-color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  position: fixed;
  top: 100px;
  z-index: 500;
}

.containerCategorias {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.containerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.menuItemC {
  position: relative;
  margin-top: 16px;
  transition: border-bottom 0.23s ease-in-out, background 0.23s linear;
  cursor: pointer;
  z-index: 3000;
  height: 100%;
  padding-left: 7px;
  padding-right: 7px;
}

.menuItemC:hover {
  background-color: #6198c2;
}

/* MENU DESPLEGABLE */

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  width: 100%;
  height: 100%;
}

.cate {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  width: 60%;
  height: 100%;
  padding: 0;
}

.menuItem {
  position: relative;
  transition: border-bottom 0.23s ease-in-out, background 0.23s linear;
  cursor: pointer;
  z-index: 3000;
}

.categories {
  display: flex;
  width: 100%;
  height: 100%;
}

.cateA {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spanMenu {
  display: flex;
  color: white;
  font-size: 14px;
}

.subMenu .menuItem:hover {
  color: white;
  border-bottom: 1px solid #6198c2;
}

.subcateA:hover {
  color: #6198c2;
}

.menuItemC:hover .subMenu,
.menuItem:hover .subMenu:hover,
.menuItemC:focus-within .subMenu,
.menuItem:focus-within .subMenu:hover {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.subMenu {
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 0;
  visibility: hidden;
  display: none;
  opacity: 0;
  padding: 0.6em 0;
  list-style: none;
  background: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.57);

  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.47);

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.47);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  margin-top: 1px;
  z-index: 10000;
}
.subMenu .menuItem {
  margin: 0.3em 0;
  width: 10em;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  z-index: 3000;
}

.subcateA {
  color: black;
  font-size: 13px;
}

.menuItemC a:link,
.menuItemC a:visited,
.menuItemC a:active,
.menuItemC a:hover {
  text-decoration: none;
  color: white;
}

.containerBuscador {
  display: none;
}
.result {
  display: none;
}

@media only screen and (max-width: 1920px) {
  .containerBuscador {
    display: none;
  }
}
@media only screen and (max-width: 1440px) {
  .containerBuscador {
    display: none;
  }
}
@media only screen and (max-width: 1400px) {
  .containerBuscador {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .containerBuscador {
    display: none;
  }
}
@media only screen and (max-width: 1140px) {
  .containerBuscador {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .containerBuscador {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .subMenu .menuItem {
    width: 8em;
  }
}

@media only screen and (max-width: 768px) {
  .categoriesContainer {
    display: none;
  }
  .ghostMenu{
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .categoriesContainer {
    width: 100%;
    height: 55px;
    display: flex;
    top:100px;
    background-color: #e96d19;
  }

  .ghostMenu{
    display: block;
    width: 100%;
    height: 55px;
  }

  .containerCategorias {
    display: none;
  }
  .containerMenu {
    display: none;
  }
  .ubicacion {
    display: none;
  }

  /* BUSCADOR */
  .containerBuscador {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  /* BUSCADOR */
  .form button {
    border: none;
    background: none;
    color: #0d64a3;
    cursor: pointer;
  }
  .form {
    position: relative;
    width: 100%;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
  }
  /* DROPDOWN */
  .contresult {
    position: absolute;
    z-index: 3001;
    width: 40%;
    left: 30%;
    top: 0;
    display: flex;
    justify-content: center;
  }
  .result {
    display: block;
    position: absolute;
    background-color: whitesmoke;
    z-index: 3001;
    width: 375px;
    top: 144px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.75);
  }
  .itemsdrop:hover {
    background-color: white;
  }
  .spanItem {
    text-decoration: none;
    color: black;
  }
  .imgSearch {
    height: 40px;
  }
  .itemsdrop {
    height: 60px;
    border-top: 1px solid #0d64a3;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  /* ESTILO Input */
  .input {
    font-size: 0.96rem;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
  }
  /* Estilo de animación border */
  .form:before {
    content: "";
    position: absolute;
    background: #0d64a3;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  /* Hover on Input */
  .form:focus-within {
    border-radius: 1px;
  }

  input:focus {
    outline: none;
  }
  /* here is code of animated border */
  .form:focus-within:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  /* styling of close button */
  /* == you can click the close button to remove text == */
  .reset {
    border: none;
    background: none;
    opacity: 0;
    visibility: hidden;
  }
  /* close button shown when typing */
  input:not(:-moz-placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:-ms-input-placeholder) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  /* sizing svg icons */
  .form svg {
    width: 17px;
    margin-top: 3px;
  }
}

@media only screen and (max-width: 375px) {
  .categoriesContainer {
    width: 100%;
    height: 55px;
    display: flex;
  }
  .containerCategorias {
    display: none;
  }
  .containerMenu {
    display: none;
  }
  .ubicacion {
    display: none;
  }

  /* BUSCADOR */
  .containerBuscador {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* DROPDOWN */
  .result {
    width: 350px;
  }
  /* BUSCADOR */
  .form button {
    border: none;
    background: none;
    color: #0d64a3;
    cursor: pointer;
  }
  .form {
    position: relative;
    width: 350px;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: 30px;
    -webkit-transition: border-radius 0.5s ease;
    transition: border-radius 0.5s ease;
    background: #fff;
  }
  /* ESTILO Input */
  .input {
    font-size: 0.78rem;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
  }
  /* Estilo de animación border */
  .form:before {
    content: "";
    position: absolute;
    background: #0d64a3;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    border-radius: 1px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  /* Hover on Input */
  .form:focus-within {
    border-radius: 1px;
  }

  input:focus {
    outline: none;
  }
  /* here is code of animated border */
  .form:focus-within:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  /* styling of close button */
  /* == you can click the close button to remove text == */
  .reset {
    border: none;
    background: none;
    opacity: 0;
    visibility: hidden;
  }
  /* close button shown when typing */
  input:not(:-moz-placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:-ms-input-placeholder) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  input:not(:placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
  }
  /* sizing svg icons */
  .form svg {
    width: 17px;
    margin-top: 3px;
  }
}
